<template>
	<v-layout column fill-height>
		<v-form v-model="isValid">
			<w-section :title="$t('workflows.general_information') + ' :'">
				<v-layout column>
					<w-text-input
						v-model="workflow.name"
						:disabled="isReadOnly"
						:label="$t('workflows.name')"
						maxlength="191"
						:readonly="isReadOnly"
						required
					/>
				</v-layout>
			</w-section>
			<w-section :title="$t('workflows.statuses') + ' :'">
				<WorkflowStatusesInput v-model="workflow.statuses" :readonly="isReadOnly" :width="width" />
			</w-section>
		</v-form>
		<v-spacer />
		<v-flex v-if="!isReadOnly" shrink text-xs-right>
			<w-btn-save :disabled="!isValid || isPristine" :loading="loading" @click="save()" />
		</v-flex>
	</v-layout>
</template>

<script>
import WorkflowManagerModuleGuard from '@/mixins/ModulesGuards/Workflow/WorkflowManagerModuleGuard'

import { mapGetters, mapState } from 'vuex'

export default {
	name: 'WorkflowForm',
	components: {
		WorkflowStatusesInput: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Workflows/WorkflowStatusesInput')
		})
	},
	mixins: [WorkflowManagerModuleGuard],
	props: {
		value: {
			default: null,
			required: false,
			type: Object
		},
		width: {
			required: true,
			type: Number
		}
	},
	data: function () {
		return {
			isValid: false,
			loading: false,
			workflow: {
				name: null,
				statuses: []
			},
			pristine: {},
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
		}),
		...mapGetters({
			userHasWriteAccess: 'workflows/userHasWriteAccess'
		}),
		isPristine: function () {
			return JSON.stringify(this.workflow) === JSON.stringify(this.pristine);
		},
		isReadOnly: function () {
			return this.workflow.is_readonly || !this.userHasWriteAccess
		}
	},
	watch: {
		value: {
			handler: 'fillWorkflowObject'
		}
	},
	mounted: function () {
		this.fillWorkflowObject()
	},
	methods: {
		fillWorkflowObject: function () {
			this.workflow = { ...this.workflow, ...this.value };
			this.resetPristineState();
		},
		resetPristineState: function() {
			this.pristine = JSON.parse(JSON.stringify(this.workflow)); // Deep copy to reset the pristine state
		},
		async save() {
			this.loading = true;

			try {
				const id = this.value?.id;
				await (id ? this.updateWorkflow(id) : this.createWorkflow());
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workflows.saved'));
				this.resetPristineState();
			} finally {
				this.loading = false;
			}
		},
		async updateWorkflow(id) {
			const workflow = await this.service.modifyWorkflow(this.vendorId, id, this.workflow);
			this.eventBus.emit(this.events.WORKFLOW_MODIFIED, workflow);
		},
		async createWorkflow() {
			const workflow = await this.service.createWorkflow(this.vendorId, this.workflow);
			this.eventBus.emit(this.events.WORKFLOW_CREATED, workflow);
		}
	}
}
</script>
